<template>
  <div>
    <BTable
      id="players-table"
      class="text-left text-center adminPlayersTable"
      head-variant="dark"
      :items="payouts"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      responsive
      :striped="true"
      hover
      small
      sort-icon-left
      auto-width
    >
      <template v-slot:cell(firstname)="data">
        <div class="adminTableRow text-nowrap">
          {{ data.item.FirstName }} {{ data.item.LastName }} 
          <a :href="`mailto:` + data.item.EmailAddress">
            <i
              class="fa fa-envelope"
              style="cursor:pointer;color:white;"
              :title="data.item.EmailAddress"
            />
          </a>
        </div>
      </template>
      <template v-slot:cell(activeheroes)="data">
        <div v-if="data.item.ActiveHeroes > 0" class="adminTableRow text-nowrap">
          {{ data.item.ActiveHeroes }}
        </div>
        <div v-else style="color:#555;">
          {{ data.item.ActiveHeroes }}
        </div>
      </template>
      <template v-slot:cell(upcomingheroes)="data">
        <div v-if="data.item.UpComingHeroes > 0" class="adminTableRow text-nowrap">
          {{ data.item.UpComingHeroes }}
        </div>
        <div v-else style="color:#555;">
          {{ data.item.UpComingHeroes }}
        </div>
      </template>
      <template v-slot:cell(cancelledheroes)="data">
        <div v-if="data.item.CancelledHeroes > 0" class="adminTableRow text-nowrap">
          {{ data.item.CancelledHeroes }}
        </div>
        <div v-else style="color:#555;">
          {{ data.item.CancelledHeroes }}
        </div>
      </template>
      <template v-slot:cell(incompleteheroes)="data">
        <div v-if="data.item.IncompleteHeroes > 0" class="adminTableRow text-nowrap">
          {{ data.item.IncompleteHeroes }}*
        </div>
        <div v-else style="color:#555;">
          {{ data.item.IncompleteHeroes }}
        </div>
      </template>
    </BTable>
    
    <div
      style="color:white;font-size: smaller;"
    >
      * for any incomplete heroes, please note that the player may choose a different club on checkout.
    </div>
    
    <div v-if="payouts.length > 0">
      <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
        <mdb-btn
          v-show="!spinning"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
          @click="getMyClubPlayersCsv()"
        >
          DOWNLOAD
        </mdb-btn>
        <mdb-btn
          v-show="spinning"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'Players',
  components: { },
  data () {
    return {
      currentPage: 1,
      payouts: [],
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      spinning: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'FirstName', label: 'Player', sortable: true },
          { key: 'ActiveHeroes', label: 'Active Heroes' },
          { key: 'UpComingHeroes', label: 'Upcoming Heroes' },
          { key: 'CancelledHeroes', label: 'Cancelled Heroes' },
          { key: 'IncompleteHeroes', label: 'Incomplete Heroes' },
        ]
      } else {
        return [
          { key: 'FirstName', label: 'Player', sortable: true },
          { key: 'ActiveHeroes', label: 'Active Heroes' },
          { key: 'UpcomingHeroes', label: 'Upcoming Active' },
          { key: 'CancelledHeroes', label: 'Cancelled Active' },
          { key: 'IncompleteHeroes', label: 'Incomplete Active' },
        ]
      }
    },
    perPage () {
      return this.payouts.length
    },
    rows () {
      return this.payouts.length
    }
  },
  mounted () {
    this.getClubAdminPlayerList()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getClubAdminPlayerList () {
      this.$emit('loading', true)
      try {
        const res = await adminService.getClubAdminPlayerList(this.userData.access_token)
        this.payouts = res.data != null ? res.data : []
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.$emit('loading', false)
      }, 500)
    },
    getMyClubPlayersCsv () {
      this.spinning = true
      adminService.getMyClubPlayersCsv(this.userData.access_token).then((res) => {
        this.spinning = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinning = false
      })
    }
  }
}
</script>

<style>
.adminPlayersTable * {
  font-size: clamp(0.5rem, 1.9vw, 1.1rem) !important;
}
</style>
